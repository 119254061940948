import {
  HeroSection,
  HeroSectionProps,
  useCaseType,
} from '@softopus-io/rimac-ui-lib';

import { HeroVideoSectionFragment } from '@/api';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface HeroVideoSectionProps {
  hero?: HeroVideoSectionFragment | null;
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
}

export default function HeroVideoSection({
  hero,
  titleMaxWidth,
  subtitleMaxWidth,
}: HeroVideoSectionProps) {
  const heroProps: HeroSectionProps = {
    useCase: 'technology' as useCaseType,
    enabledLazyLoading: true,
    titleMaxWidth,
    subtitleMaxWidth,
    title: hero?.title || '',
    subtitle: hero?.subtitle || '',
    buttonHref: hero?.button?.path || '',
    buttonText: hero?.button?.label || '',
    heroVideo: {
      enabledLazyLoading: true,
      pauseVideo: hero?.heroVideo?.pauseVideo || undefined,
      video: {
        posterAlt: hero?.heroVideo?.poster_alt || '',
        posterSrc: getCdlPublicId(
          hero?.heroVideo?.poster?.data?.attributes?.url || '',
        ),
        videoSrc: getCdlPublicId(
          hero?.heroVideo?.video?.data?.attributes?.url || '',
        ),
        autoplay: hero?.heroVideo?.autoplay || true,
        blurHash:
          hero?.heroVideo?.poster?.data?.attributes?.blurhash ||
          'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
        controls: hero?.heroVideo?.controls || false,
        fullscreen: hero?.heroVideo?.fullscreen || true,
        loop: hero?.heroVideo?.loop || true,
        muted: hero?.heroVideo?.muted || true,
        pauseVideo: hero?.heroVideo?.pauseVideo || false,
      },
    },
    heroVideoPreview: hero?.showPreviewVideo
      ? {
          enabledLazyLoading: true,
          video: {
            alt: hero?.previewVideo?.poster_alt || '',
            imgPosterSrc:
              getCdlPublicId(
                hero?.previewVideo?.poster?.data?.attributes?.url,
              ) || 'rimac/ui-lib/sample',
            imgPosterSrcMobile:
              getCdlPublicId(
                hero?.previewVideo?.mobilePoster?.data?.attributes?.url,
              ) || 'rimac/ui-lib/sample',
            fullVideo: {
              enabledLazyLoading: true,
              posterAlt: hero?.previewVideo?.poster_alt || '',
              posterSrc:
                getCdlPublicId(
                  hero?.previewVideo?.poster?.data?.attributes?.url,
                ) || 'rimac/ui-lib/sample',
              videoSrc:
                getCdlPublicId(
                  hero?.previewVideo?.video?.data?.attributes?.url,
                ) || ' ',
              autoplay: hero?.previewVideo?.autoplay || true,
              blurHash:
                hero?.previewVideo?.poster?.data?.attributes?.blurhash ||
                'L35rfH^$xZS%Q*t:tni^M{Zyxwxv',
              controls: hero?.previewVideo?.controls || false,
              fullscreen: hero?.previewVideo?.fullscreen || true,
              loop: hero?.previewVideo?.loop || true,
              muted: hero?.previewVideo?.muted || false,
              pauseVideo: hero?.previewVideo?.pauseVideo || false,
            },
            blurHash:
              hero?.previewVideo?.poster?.data?.attributes?.blurhash ||
              'L35rfH^$xZS%Q*t:tni^M{Zyxwxv',
            videoDuration: hero?.previewVideoLength || '',
          },
          onClick: () => {},
        }
      : undefined,
  };

  return <HeroSection {...heroProps} />;
}
