import {
  Footer as FooterComponent,
  useCaseType,
} from '@softopus-io/rimac-ui-lib';

import { FooterQueryQuery } from '@/api';

export const Footer = ({ data }: { data: FooterQueryQuery }) => {
  const footerData = data?.footer?.data?.attributes;

  const columns = (footerData?.column || []).map((column) => ({
    label: column?.label || '',
    links: (column?.link || []).map((link) => ({
      label: link?.label || '',
      path: link?.path || '',
    })),
  }));

  const socials = {
    label: footerData?.socials?.label || '',
    instagram: footerData?.socials?.instagram || undefined,
    linkedin: footerData?.socials?.linkedin || undefined,
    tiktok: footerData?.socials?.tiktok || undefined,
    youtube: footerData?.socials?.youtube || undefined,
  };

  const policies = {
    label: footerData?.policies?.label || '',
    links: (footerData?.policies?.link || []).map((link) => ({
      path: link?.path || '',
      label: link?.label || '',
      useCase: 'technology' as useCaseType,
    })),
  };

  return (
    <FooterComponent
      columns={columns}
      socials={socials}
      policies={policies}
      useCase={'technology' as useCaseType}
    />
  );
};
