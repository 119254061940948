import {
  headingTagType,
  imageCaseStudyType,
  PartnersBlogSection,
  PartnersBlogSectionProps,
  useCaseType,
} from '@softopus-io/rimac-ui-lib';

import { NewsroomFragment } from '@/api';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface Props {
  data?: NewsroomFragment | null;
}

export const Partners = ({ data }: Props) => {
  const partnersProps: PartnersBlogSectionProps = {
    useCase: 'technology' as useCaseType,
    headingTag: 'h2' as headingTagType,
    heading: data?.title || undefined,
    anchor: data?.anchor || undefined,
    caseStudies: (data?.articles?.data || []).map((article) => {
      const articleAttributes = article?.attributes?.preview;
      return {
        image: {
          imgSrc:
            getCdlPublicId(
              articleAttributes?.img_desktop?.data?.attributes?.url,
            ) || 'rimac/ui-lib/sample',
          alt: articleAttributes?.alt || '',
          blurHash:
            articleAttributes?.img_desktop?.data?.attributes?.blurhash ||
            'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
        },
        tagName: articleAttributes?.tag || undefined,
        tagType: (articleAttributes?.tagColor || 'light') as imageCaseStudyType,
        title: articleAttributes?.title || '',
        date: articleAttributes?.date || '',
        link: articleAttributes?.link_path || '',
      };
    }),
    logos: (data?.logo || []).map((logo) => ({
      image: {
        imgSrc:
          getCdlPublicId(logo?.svg.data?.attributes?.url) ||
          'rimac/ui-lib/sample',
        alt: logo?.alt || '',
      },
    })),
    buttonHref: data?.button?.path || '',
    buttonText: data?.button?.label || '',
  };
  return <PartnersBlogSection {...partnersProps} titleCenter />;
};
